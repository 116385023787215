import { mobileRegEx } from '/src/functions/regEx'

const datasoapValidatePhone = async (value) => {
    let mobileResult = false
    let landlineResult = false

    const isMobileNumber = mobileRegEx.test(value)

    if (isMobileNumber) {
        //Test mobile number
        await fetch(`https://api.datasoap.co.uk/?output=JSON&number=${value}&type=HLR`, {
            method: 'POST',
            headers: {
                Authorization: process.env.GATSBY_DATASOAP_AUTH
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    mobileResult = true // If the service fails, let the number go through anyway
                }
            })
            .then((data) => {
                mobileResult = data.DataSoapAPIResponse.HLRResult.On || false
            })
            .catch((error) => {
                console.log('Error with Datasoap mobile check:', error)
                mobileResult = true // If the service fails, let the number go through anyway
            })
    } else {
        // Test landline number
        await fetch(`https://api.datasoap.co.uk/?output=JSON&number=${value}&type=Landline`, {
            method: 'POST',
            headers: {
                Authorization: process.env.GATSBY_DATASOAP_AUTH
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    landlineResult = true // If the service fails, let the number go through anyway
                }
            })
            .then((data) => {
                landlineResult = data.DataSoapAPIResponse.LandlineLookupResult.IsActive || false
            })
            .catch((error) => {
                console.log('Error with Datasoap landline check:', error)
                landlineResult = true // If the service fails, let the number go through anyway
            })
    }

    // If either comes back true, number is valid
    if (mobileResult || landlineResult) {
        return true
    } else {
        return false
    }
}

export default datasoapValidatePhone
