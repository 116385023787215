import React, { useState, useContext, useEffect} from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { UtmContext } from '/src/contexts/UtmContext'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { phoneNumberRegEx } from '/src/functions/regEx'
import datasoapValidatePhone from '/src/functions/forms/datasoapValidatePhone'
import { formatOfficeLineDob, formatHumanDateFromSingleValues } from '/src/functions/timeFormatter'
import submitLead from '/src/functions/forms/submitLead'
import cleanedLeadsourceParam from '/src/functions/forms/formUrlLeadsourceParamFilter'
import './styles.scss'

const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const InnerSection = loadable(() => import('/src/components/Structure/InnerSection'))
const InsurerLogoStrip = loadable(() => import('/src/components/PageBuilder/layouts/global/InsurerLogoStrip'))
const RequiredText = loadable(() => import('/src/components/Form/Required/RequiredText'))
const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))

const StepContactDetails = loadable(() => import('/src/forms/LeadGen/SinglePageLeadGenJourney/StepContactDetails'))
const StepCover = loadable(() => import('/src/forms/LeadGen/SinglePageLeadGenJourney/StepCover'))
const StepDob = loadable(() => import('/src/forms/LeadGen/SinglePageLeadGenJourney/StepDob'))
const StepPartner = loadable(() => import('/src/forms/LeadGen/SinglePageLeadGenJourney/StepPartner'))
const StepProtect = loadable(() => import('/src/forms/LeadGen/SinglePageLeadGenJourney/StepProtect'))
const StepSex = loadable(() => import('/src/forms/LeadGen/SinglePageLeadGenJourney/StepSex'))
const StepSmoker = loadable(() => import('/src/forms/LeadGen/SinglePageLeadGenJourney/StepSmoker'))
const StepUserDetails = loadable(() => import('/src/forms/LeadGen/SinglePageLeadGenJourney/StepUserDetails'))

const SinglePageLeadGenJourney = ({ title, leadSource, firebaseCollection, successPage }) => {
    const [officeLineUtms] = useContext(UtmContext)
    const [isSubmitted, setSubmitted] = useState(false)

    //Grab Url Params to set defaults in the form
    //set up states
    const [urlLeadsource, seturlLeadsource] = useState('')
    const [mdegFlag, setmdegFlag] = useState('')
    const [mdegValue, setmdegValue] = useState('')

    useEffect((leadSource) => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        //check param exists and collect from url
        if(urlParams.has('ls')){
            seturlLeadsource(cleanedLeadsourceParam(urlParams.get('ls')))
        } else {
            seturlLeadsource(leadSource || process.env.GATSBY_OFFICELINE_SOURCE)
        }
        
        if(urlParams.has('_ef_transaction_id')){
            setmdegFlag('Y')
        } else {
            setmdegFlag('N')
        }

        if(urlParams.has('_ef_transaction_id')){
            setmdegValue(urlParams.get('_ef_transaction_id'))
        } else {
            setmdegValue('N')
        }
        
    }, [])

    //console.log(urlLeadsource)
    //console.log("EF Parampresent: "+mdegFlag)
    //console.log("EF Value Catptured: "+mdegValue)

    const validationSchema = Yup.object({
        partner: Yup.string().required('This field is required'),
        protect: Yup.string(),
        smoker: Yup.string().required('This field is required'),
        coverAmount: Yup.string(),
        coverPeriod: Yup.string(),
        dobDay: Yup.string().required('Day is required'),
        dobMonth: Yup.string().required('Month is required'),
        dobYear: Yup.string().required('Year is required'),
        title: Yup.string(),
        firstname: Yup.string().required('First name is required'),
        lastname: Yup.string().required('Last name is required'),
        sex: Yup.string().required('This field is required'),
        email: Yup.string(),
        phone: Yup.string()
            .max(15, 'Cannot be longer than 15 digits')
            .matches(phoneNumberRegEx, 'Phone number is not valid')
            .required('Contact number is required')
            .test('datasoap', 'Phone number is not valid', async function (value) {
                return datasoapValidatePhone(value)
            }),
        postcode: Yup.string().max(10, 'Cannot be longer than 10 digits')
    })

    return (
        <Formik
            initialValues={{
                partner: '',
                protect: '',
                smoker: '',
                coverPeriod: '',
                coverAmount: '',
                dobDay: '',
                dobMonth: '',
                dobYear: '',
                title: '',
                firstname: '',
                lastname: '',
                sex: '',
                email: '',
                phone: '',
                postcode: ''
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, actions) => {
                const firebaseData = {
                    createdAt: new Date(),
                    source: urlLeadsource,
                    subSource: officeLineUtms.utmCampaign,
                    subSubSource: officeLineUtms.subSubSource,
                    partner: values.partner,
                    protect: values.protect,
                    smoker: values.smoker,
                    coverPeriod: values.coverPeriod,
                    coverAmount: values.coverAmount,
                    dob: formatHumanDateFromSingleValues(values.dobDay, values.dobMonth, values.dobYear),
                    title: values.title,
                    firstname: values.firstname,
                    lastname: values.lastname,
                    sex: values.sex,
                    email: values.email,
                    phone: values.phone,
                    postcode: values.postcode,
                    mdeg: mdegFlag,
                    mdegValue: mdegValue,
                }

                const coverPeriod = values.coverPeriod === `I don't know` ? 0 : Number(values.coverPeriod.slice(0, -6))
                const coverAmount =
                    values.coverAmount === `I don't know` ? 0 : Number(values.coverAmount.replace(/[^0-9-]+/g, ''))

                let olData = new URLSearchParams({
                    source: urlLeadsource,
                    cover_length: coverPeriod,
                    cover_amount: coverAmount,
                    app1_smoker: values.smoker,
                    app1_dob: formatOfficeLineDob(values.dobDay, values.dobMonth, values.dobYear),
                    app1_first_name: values.firstname,
                    app1_last_name: values.lastname,
                    app1_gender: values.sex,
                    app1_email: values.email,
                    app1_telephone_1: values.phone,
                    app1_postcode: values.postcode,
                    // Goes to 'sub-source' field in OL
                    affiliate_id: officeLineUtms.utmCampaign,
                    // Goes to 'sub-sub-source' field in OL
                    sub_sub_source: officeLineUtms.subSubSource
                })

                if (values.partner === 'YES') {
                    olData.append('app2_first_name', 'Partner')
                    olData.append('app2_last_name', 'Partner')
                    olData.append('app2_dob', '1900-01-01')
                }

                if (values.title !== 'Please select' || values.title !== 'Other') {
                    olData.append('app1_title', values.title)
                }

                const submitOptions = {
                    actions: actions,
                    firebaseCollection: firebaseCollection || 'leads-website-single-page',
                    firebaseData: firebaseData,
                    olData: olData,
                    successPage: successPage || '/submission-success',
                    mdegValue: mdegValue,
                }

                setSubmitted(true)

                await submitLead(submitOptions)
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => (
                <Form className={`c-single-page-journey`}>
                    <InnerSection>
                        <h1 className="c-single-page-journey__title">{title}</h1>
                    </InnerSection>

                    <InnerSection variant={'alt'}>
                        <InsurerLogoStrip className="c-single-page-journey__insurer-strip" />
                    </InnerSection>

                    <InnerSection>
                        <RequiredText />

                        <StepPartner isSubmitting={isSubmitting} isSubmitted={isSubmitted} />
                    </InnerSection>

                    <InnerSection variant={'alt'}>
                        <StepProtect isSubmitting={isSubmitting} isSubmitted={isSubmitted} />
                    </InnerSection>

                    <InnerSection>
                        <StepSmoker isSubmitting={isSubmitting} isSubmitted={isSubmitted} />
                    </InnerSection>

                    <InnerSection variant={'alt'}>
                        <StepCover isSubmitting={isSubmitting} isSubmitted={isSubmitted} />
                    </InnerSection>

                    <InnerSection>
                        <StepDob isSubmitting={isSubmitting} isSubmitted={isSubmitted} />
                    </InnerSection>

                    <InnerSection variant={'alt'}>
                        <StepUserDetails isSubmitting={isSubmitting} isSubmitted={isSubmitted} />
                    </InnerSection>

                    <InnerSection>
                        <StepSex isSubmitting={isSubmitting} isSubmitted={isSubmitted} />
                    </InnerSection>

                    <InnerSection variant={'alt'}>
                        <StepContactDetails isSubmitting={isSubmitting} isSubmitted={isSubmitted} />
                    </InnerSection>

                    {/* {disclaimer && (
                    <Alert content={disclaimer} />
                )} */}

                    <InnerSection>
                        <CtaContainer align={'centered'} variant={'compact'}>
                            <SubmitButton
                                initialText={'Get My Quotes'}
                                sentText={'Thank You!'}
                                isSubmitting={isSubmitting}
                                isSubmitted={isSubmitted}
                            />
                        </CtaContainer>
                    </InnerSection>
                </Form>
            )}
        </Formik>
    )
}

SinglePageLeadGenJourney.propTypes = {
    title: PropTypes.string.isRequired,
    leadSource: PropTypes.string,
    firebaseCollection: PropTypes.string
}

export default SinglePageLeadGenJourney
