import { navigate } from 'gatsby'
import firebase from 'gatsby-plugin-firebase'
import { pushToGtmDataLayer } from '/src/functions/gtm'

const isBrowser = typeof window !== 'undefined' && !window.STORYBOOK_ENV

if (isBrowser) {
    firebase.firestore().settings({ experimentalForceLongPolling: true, merge: true })
}

const submitLead = async (options) => {
    let formSuccess = false

    const sendToOfficeLine = async (options) => {
        try {
            // Sending to Office Line...
            const response = await fetch(`https://pline.uk/web/selfgenleadsubmit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: options.olData
            })

            if (response.ok) {
                // All ok sending to Office Line, proceed as normal
                options.actions.setSubmitting(false)

                const data = await response.json()

                if (data) {
                    // Save to local storage
                    sessionStorage.setItem('leadId', data.lead.id)

                    // Sending to GTM Data Layer...
                    await pushToGtmDataLayer([
                        ['event', 'lead_form_validated'],
                        ['lead_id', data.lead.id]
                    ])

                    formSuccess = true
                } else {
                    console.error('No data sent to Office Line')
                }
            } else {
                console.error(`Couldn't send to Office Line: ${response.status}`)
            }
        } catch (err) {
            console.error('Could not connect to Office Line:', err)
        }
    }

    const sendToFirebase = async (options) => {
        try {
            await firebase.firestore().collection(options.firebaseCollection).add(options.firebaseData)
        } catch (err) {
            console.error('Could not connect to Firebase:', err)
        }
    }

    const sendToMdeg = async (options) => {

        if (options.mdegValue !== "N") {

            const everflowUrl = 'https://www.wqm0gtrk.com/?nid=2818&transaction_id='+options.mdegValue+'&verification_token=BmxKD2ncZ3PjMM7gp0gnfmtCKkZvyZ';

            try {
                const response = await fetch(everflowUrl)

                if (!response.ok) {
                    console.error('Error connecting to MDEG ef:', response.status)
                  }

            } catch (err) {
                console.error('Could not connect to MDEG ef:', err)
            }
            
        }

    }

    const sendData = async (options) => {
        try {
            await sendToFirebase(options)
            await sendToOfficeLine(options)
            await sendToMdeg(options)

            if (formSuccess) {
                // Everything is complete
                navigate(options.successPage)
            } else {
                console.error('There was an error with completing the form')
            }
        } catch (err) {
            console.error('There was a critical error with the form')
        }
    }

    sendData(options)
}

export default submitLead
